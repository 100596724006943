<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card>

      <b-row>
        <b-col cols="12" md="4">

        </b-col>
        <b-col cols="12" md="4">
          <div class="clearfix mb-2" style="margin-top: 90px;">
            <p  class="vx-row">
              <img src="@/assets/images/Email.png" class="ml-1 mr-1 socialImage" />
              <span class="mt-4 ml-2  vx-row" style="font-size:20px;text-align: center;">{{ $t('Emailus') }}</span>
            </p>
          </div>

            <vs-input label-placeholder="Name" name="UserName" :placeholder="$t('Name')" v-model="model.UserName"
              class="w-full center" />
            <!-- <span class="text-danger text-sm">{{ errors.first("UserName") }}</span> -->

            <vs-input v-validate="'required|email'" data-vv-validate-on="blur" name="email" type="email"
              label-placeholder="Email" :placeholder="$t('Email')" v-model="model.Email" class="mt-6" />

            <!-- <span class="text-danger text-sm">{{ errors.first("email") }}</span> -->
            <vs-input label-placeholder="Phone" :placeholder="$t('PhoneNumber')" v-model="model.PhoneNumber"
              class=" mt-6" />
            <vs-input label-placeholder="Subject" :placeholder="$t('Subject')" v-model="model.Subject" class=" mt-6" />
            <vs-textarea :placeholder="$t('Message')" v-model="model.Message" class="mt-5"
              style="width: 200px; height: 50px;" />

            <div>
              <b-button type="border" @click="back()" class="mt-2">
                {{ $t("back") }}
              </b-button>
              <b-button class="mx-2 mt-2" @click="sendEmail">
                {{ $t("Send") }}
              </b-button>
            </div>

        </b-col>
        <b-col cols="12" md="4">

        </b-col>
      </b-row>

    </b-card>

  </div>


</template>

<script>
//import RegisterFirebase from "./RegisterFirebase.vue"
//import RegisterAuth0 from "./RegisterAuth0.vue"
import {
  BTab, BTabs, BAlert, BAvatar, BBadge, BButton
  , BCard, BCol, BDropdown, BDropdownItem
  , BFormInput, BLink, BMedia, BPagination,
  BRow, BTable, BTooltip, BImg
} from 'bootstrap-vue';

export default {
  components: {

    //RegisterFirebase,
    //RegisterAuth0
    BTab, BTabs, BAlert, BAvatar, BBadge, BButton
    , BCard, BCol, BDropdown, BDropdownItem
    , BFormInput, BLink, BMedia, BPagination,
    BRow, BTable, BTooltip, BImg,

  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.model.Email && this.model.Subject && this.model.Message
    },

  },
  data() {
    return {
      model: {}
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    sendEmail() {
      this.$store
        .dispatch("auth/SendMailForContactUs", this.model).then(() => {
          window.showSuccess();
          this.back();
        })

    }
  },
};
</script>

<style lang="scss">
.mycard .vx-card {
  background: unset;
}

.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }

}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.material-icons :lang(en) {
  font-family: unset
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
  width: 50%;
}

li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
